import { useContext, useEffect, useState } from 'react';

import { IamTableProps } from '../components/tables/iam-table/IamTable';
import { SnackBarContext } from '../providers/SnackbarProvider';
import { request } from '../util/request';

export const USER_LIST_API = `${process.env.GATSBY_API_LIST_USERS}?key=${process.env.GATSBY_API_KEY}`;

export interface UseIamPageResponse {
  data: IamTableProps['data'];
  error: Error['message'] | null;
  loading: boolean;
}

export const useIamPage = (): UseIamPageResponse => {
  const [data, setData] = useState<IamTableProps['data']>([]);
  const [error, setError] = useState<Error['message'] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const snackbar = useContext(SnackBarContext);

  useEffect(() => {
    const handleError = (error: Error) => {
      setError(error.message);
      snackbar.showFeedback({
        severity: 'error',
        message: error.message,
      });
    };
    const getUserList = async (): Promise<IamTableProps['data']> => {
      try {
        const data: {
          response: IamTableProps['data'];
        } = await request(USER_LIST_API, { mode: 'cors' });
        return data.response;
      } catch (error) {
        handleError(error);
        return [];
      }
    };
    (async () => {
      const users = (await getUserList()) as IamTableProps['data'];
      setData(users);
      setLoading(false);
      setError(null);
    })();
  }, [snackbar]);

  return {
    data,
    error,
    loading,
  };
};
