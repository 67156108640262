import Box, { BoxProps } from '@material-ui/core/Box';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export interface ContainerCardProps extends Partial<BoxProps> {
  paperProps?: Partial<PaperProps>;
  innerProps?: Partial<BoxProps>;
}

export const ContainerCard: React.FC<ContainerCardProps> = ({
  paperProps,
  innerProps,
  className,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box display={'flex'} flexDirection={'column'} {...props}>
      <Paper
        {...paperProps}
        className={clsx(classes.paper, paperProps?.className)}
      >
        <Box p={2} flexGrow={1} {...innerProps}>
          {children}
        </Box>
      </Paper>
    </Box>
  );
};
