import { Box, Grid, Typography } from '@material-ui/core';
import PolicyIcon from '@material-ui/icons/Policy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Iam from '../components/iam/Iam';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo/SEO';
import { useIamPage } from '../hooks/useIamPage';

const useStyles = makeStyles((theme: Theme) => ({
  pageToolbar: {
    display: 'flex',
    height: '3.5rem',
    padding: '0 1.65rem .5rem 1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
  },
  pageHeader: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    fontWeight: 300,
    fontSize: '1.25rem',
  },
  pageTitle: {
    fontFamily: 'Futura, Tw Cen MT',
  },
  spc: {
    width: '1rem',
  },
}));

const IamPage = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useIamPage();
  const classes = useStyles();

  return (
    <Layout>
      <SEO title={t('iam.title')} />
      <Box className={classes.pageToolbar}>
        <div className={classes.pageHeader}>
          <PolicyIcon />
          <div className={classes.spc}></div>
          <div className={classes.pageTitle}>{t('iam.title')}</div>
        </div>
      </Box>
      <Box p={2}>
        <Iam {...{ data, error, loading }} maxWidth={1500} />
      </Box>
    </Layout>
  );
};

export default IamPage;
