import React from 'react';
import { UseIamPageResponse } from 'src/hooks/useIamPage';

import {
  ContainerCard,
  ContainerCardProps,
} from '../surfaces/visualization/ContainerCard';
import { IamTable, IamTableProps } from '../tables/iam-table/IamTable';

export interface IamProps
  extends ContainerCardProps,
    IamTableProps,
    UseIamPageResponse {}

export const Iam: React.FC<IamProps> = (props) => {
  const { data, error, loading, ...containerProps } = props;

  return (
    <ContainerCard innerProps={{ p: 0 }} {...containerProps}>
      <IamTable {...{ data, error, loading }} />
    </ContainerCard>
  );
};

export default Iam;
